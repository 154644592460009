
import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Home from './components/pages/home/home';
import Categories from './components/pages/categories/categories';
import Header from './components/layout/header/header';
import Footer from './components/footer/footer';
import Menus from './components/pages/menus/menus';
import Cart from './components/pages/cart/cart';
import Dishes from './components/pages/dishes/dishes';
import Schedule from './components/pages/schedule/schedule';
import Settings from './components/pages/settings/settings';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsOfUse from './components/pages/TermsOfUse';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/items" component={Categories} />
          <Route path="/menus" component={Menus} />
          <Route path="/cart" component={Cart} />
          <Route path="/dishes" component={Dishes} />
          <Route path="/schedule" component={Schedule} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-of-use" component={TermsOfUse} />
          
          {/* <Route path="/settings" component={Settings}/> */}
          <Redirect to="/" />
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;