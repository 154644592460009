// cook-assist/src/components/pages/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      {<div>
      <h1>Privacy Policy</h1>
      <p><strong>Last Updated: 24th Sep 2024</strong></p>

      <h2>1. Information We Collect</h2>
      <p>We may collect information about you in a variety of ways, including:</p>
      <ul>
        <li><strong>Personal Information:</strong> When you register for our services, we may collect personal information, such as your name, email address, phone number, and billing information.</li>
        <li><strong>Usage Data:</strong> We collect information about your interactions with our services, including IP address, browser type, access times, pages viewed, and the referring URL.</li>
        <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to enhance your experience on our site.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We may use the information we collect for various purposes, including to:</p>
      <ul>
        <li>Provide, maintain, and improve our services.</li>
        <li>Process transactions and send you related information, including purchase confirmations and invoices.</li>
        <li>Communicate with you about your account or transactions.</li>
        <li>Send you marketing communications, with your consent.</li>
        <li>Monitor usage and analyze trends to improve our services.</li>
      </ul>

      <h2>3. Disclosure of Your Information</h2>
      <p>We do not sell your personal information. We may share your information in the following circumstances:</p>
      <ul>
        <li><strong>Service Providers:</strong> We may share your information with third-party vendors who provide services on our behalf.</li>
        <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law.</li>
      </ul>

      <h2>4. Data Security</h2>
      <p>We take reasonable measures to help protect personal information from loss, theft, misuse, and unauthorized access. However, no method of transmission is 100% secure.</p>


      <h2>5. Third-Party Links</h2>
      <p>Our services may contain links to third-party websites. We are not responsible for the privacy practices of these sites.</p>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. Your continued use of our services after changes constitutes your acceptance of the new Privacy Policy.</p>

      <h2>7. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at blzeapps464@gmail.com.</p>
    </div>  }
    </div>
  );
};

export default PrivacyPolicy;