// cook-assist/src/components/pages/TermsOfUse.js
import React from 'react';

const TermsOfUse = () => {
  return (
    <div>
     
      {<div>
        <h1>Terms and Conditions</h1>   
        <p> Last updated: 24th Sep 2024</p>
      

      <h2>1. Acceptance of Terms</h2>
      <p>
        By using our software-as-a-service (SaaS) platform, you agree to these Terms and Conditions and our Privacy Policy. If you do not agree, please do not use our services.
      </p>

      <h2>2. Services Provided</h2>
      <p>
        Listing and social sharing of recipies, meal plans, and grocery lists.
      </p>

      <h2>3. User Obligations</h2>
      <p>You agree to:</p>
      <ul>
        <li>Provide accurate and complete information during registration.</li>
        <li>Maintain the confidentiality of your account and password.</li>
        <li>Notify us immediately of any unauthorized use of your account.</li>
        <li>Comply with all applicable laws and regulations while using our services.</li>
      </ul>

      <h2>4. Payment Terms</h2>
      <p>
        Free to use. No payment required.
      </p>

      <h2>5. Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless Nmbrlogic Internet Pvt Ltd, its affiliates, and their respective officers, directors, employees, and agents from any claims, losses, liabilities, damages, costs, or expenses (including full attorney's fees) arising out of or related to:
      </p>
      <ul>
        <li>Your use of the services.</li>
        <li>Your violation of these Terms and Conditions.</li>
        <li>Your infringement of any third-party rights, including intellectual property rights.</li>
      </ul>

      <h2>6. Limitation of Liability</h2>
      <p>
        To the maximum extent permitted by law, Nmbrlogic internet shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of the services, even if we have been advised of the possibility of such damages.
      </p>

      <h2>7. Termination</h2>
      <p>
        We reserve the right to suspend or terminate your access to our services at any time, without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users of our services.
      </p>

      <h2>8. Modifications to Terms</h2>
      <p>
        We may update these Terms and Conditions from time to time without notice. Your continued use of the services after any such changes constitutes your acceptance of the new Terms and Conditions.
      </p>

      <h2>9. Governing Law</h2>
      <p>
        These Terms and Conditions shall be governed by and construed in accordance with the laws of Gurgaon, Haryana, without regard to its conflict of law principles.
      </p>

      <h2>10. Contact Information</h2>
      <p>
        If you have any questions about these Terms and Conditions, please contact us at blzeapps464@gmail.com
      </p>
    </div>}
    </div>
  );
};

export default TermsOfUse;