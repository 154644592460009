import React from 'react';

const Footer = () => {
  return (
    <footer>
      <p>&copy; Cook Assist. All rights reserved.</p>
      <p>
        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> | 
        <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a>
      </p>
    </footer>
  );
};

export default Footer;